import {SelectBox } from 'devextreme-react';
import { DataGrid, Column, FilterRow, HeaderFilter, Lookup, Paging } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useUserInputHistoryApi from 'src/api/useUserInputHistoryApi';
import { GetUsersRequest, UserActionCode, UserAuthCustomDto, UserRequestType } from 'src/common/dtos';
import { useAuth } from 'src/contexts/auth';
import './user-action-history.scss';

interface IResearcher{

}

export default function UserActionHistory(props: any) {

  const { getInputActionHistory } = useUserInputHistoryApi();
  const { user, apiClient } = useAuth();
  const [researchers, setResearchers] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState(parseInt(user?.userId ?? "0"));
  const gridRef = useRef<DataGrid<object,string>>(null);

  const isAdmin : boolean = user?.roles?.includes("Admin") ?? false;

  const store = new CustomStore({
    key: 'id',
    load: (loadOptions) => {
      return getInputActionHistory(loadOptions, currentUser > 0 ? currentUser : undefined);
    }
  });

  useEffect(() => {
    (async function(){
      let req = new GetUsersRequest();
      // req.userType = UserRequestType.Researcher;
      let resp = await apiClient.get(req);

      if(resp.success && resp.data !== undefined)
      {
        setResearchers(resp.data.map(user => {
          return {
            name: user.displayName,
            id: user.id
          }
        }));
      }
      else{
        console.log(resp.publicMessage);
      }
    })()
  }, []);

  function renderTimespan(params)
  {
    // console.log(params);

    let duration = moment.duration(params.value);

    let durationString = `${duration.asMinutes().toFixed(1)} minutes`;

    return <div>{durationString}</div>
  }

  function userChange(event)
  {
    setCurrentUser(event.value);
    // console.log(event);
    // console.log(gridRef?.current);
    gridRef.current?.instance.refresh();
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>User Action History</h2>
      
      
      <div className={'content-block'}>
      {isAdmin &&
        <SelectBox className='user-drop' dataSource={researchers} displayExpr="name" valueExpr="id" defaultValue={parseInt(user?.userId ?? "0")} stylingMode='outlined' onValueChanged={userChange} />
      }
        <div className={'dx-card responsive-paddings'}>
          <DataGrid ref={gridRef} dataSource={store} remoteOperations={true} columnAutoWidth={true}>

            <Paging defaultPageSize={10} />
            <HeaderFilter visible={false} />
            <FilterRow visible={true} />
            <Column dataField="created" dataType="date">

            </Column>
            <Column dataField="description" />
            <Column
              dataField="action">
              <Lookup
                dataSource={Object.values(UserActionCode)}
              />
            </Column>
            <Column dataField="actionDuration" dataType="number" cellRender={renderTimespan}>

            </Column>

          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}


