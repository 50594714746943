import { Box, Button, ButtonGroup, CheckBox, Form, Popover, Popup, TextArea, TextBox, ValidationGroup } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { ButtonItem, GroupItem, Item as FormItem, RequiredRule, SimpleItem } from 'devextreme-react/form';


import React, { useEffect, useRef, useState } from 'react';
import { InputQueue, ResearchedLead, ResearchLeadTimezone, StopTheClockReason } from 'src/common/dtos';
import SectionBox from 'src/components/section-box/section-box';
import Stopwatch from 'src/components/stopwatch/stopwatch';
import { alert, confirm } from "devextreme/ui/dialog"
import './input-leads.scss';
import { title } from 'process';
import { useLeadInputApi } from 'src/api/useLeadInputApi';

import * as phoneChecker from 'phone-number-to-timezone';
import DataSource from 'devextreme/data/data_source';

export interface InputForm {
  name: string;
  phone: string;
  additionalPhones: string;
  url: string
  personName: string;
}

export enum ServiceTypes {

}

const services = [
  { name: "Individual", id: 46, description: "This is a single person offering a service.  Use this classification if site seem like a personal resume" },
  { name: "Global Virtual Mailbox Provider", id: 23, description: "Company offers physical mail handling.  They provide an address in any location internationally and when mail is sent there, they will either scan and email it to you or forward the mail item to another address" },
  { name: "National Virtual Mailbox Provider", id: 24, description: "Company offers physical mail handling.  They provide an address in any location for a specific county and when mail is sent there, they will either scan and email it to you or forward the mail item to another address" },
  { name: "Local Virtual Mailbox Provider", id: 25, description: "Company offers physical mail handling.  They provide an address in a small list of specific locations. When mail is sent there, they will either scan and email it to you or forward the mail item to another address" },
  { name: "Coworking", id: 26, description: "Co-working is typically priced per person.  You may have a designated desk or be allowed to site anywhere in a designated area.  There is usually no long term committment and you can pay month to month." },
  { name: "Coworking Single Site", id: 27, description: "This is a coworking space that just offers a single location for coworking" },
  { name: "Coworking Network", id: 28, description: "This is a coworking space that will give you a pass that will allow you to work from multiple locations" },
  { name: "Flexible Office Space", id: 29, description: "Office space rented by the square foot and can come in different configurations.  Usually comes with a minimum commitment of 6-12 months" },
  { name: "Flexible Office Multisite", id: 30, description: "A flexible office service with more that one location but all belonging to same organisation" },
  { name: "Flexible Office Directory", id: 31, description: "A company that lists multiple office location for multiple origanisations" },
  { name: "Office Space", id: 32, description: "Traditional office offered on lease terms.  Generally 5-10 year lease term" },
  { name: "Conference rooms", id: 42, description: "This is a meeting room that can be booked at the location" },
  { name: "Single site conference room", id: 45, description: "The company has just one building with conference room" },
  { name: "Local conference rooms", id: 43, description: "The comapny has multiple buildings with conference rooms in the same state" },
  { name: "National conference rooms", id: 44, description: "The comapny has multiple conference room in multiple states" },
  { name: "Call answering", id: 33, description: "Company will answer phones on behalf of company using their company name" },
  { name: "Email handling", id: 34, description: "They will manage emails on behalf of a company" },
  { name: "Web chat handling", id: 35, description: "They will handle live webchats on behalf of company" },
  { name: "SMS handling", id: 36, description: "They will handle phone SMS messages on behalf of company" },
];



export default function InputLeads(props: any) {

  const [currentLead, setCurrentLead] = useState<undefined | ResearchedLead>(undefined);
  const [currentInputQueue, setCurrentInputQueue] = useState<undefined | InputQueue>(undefined);
  const [selectedServices, setSelectedServices] = useState({});
  const [enabledServices, setEnabledServices] = useState({});
  const [skipPopupVisible, setSkipPopupVisible] = useState(false);
  const [skipReason, setSkipReason] = useState("");
  const [clockRunning, setClockRunning] = useState(true);
  const { getNextLead, saveResearchLead, setNotSuitable: setNotSuitableRequest, skipLead: skipLeadRequest, stopLeadClock: stopLeadClockRequest } = useLeadInputApi();
  const [stopwatchSecondOffset, setStopwatchSecondOffset] = useState<number | undefined>();
  const validationGroupRef = useRef(null);

  const leadFormRef = useRef<Form>(null);
  const [leadTrigger, setLeadTrigger] = useState(0);
  const [stopWatchKey, setStopWatchKey] = useState(0);

  const [visibleToolTip, setVisibleToolTip] = useState(0);

  useEffect(() => {


    getNextLead().then(lead => {

      let researchLead = new ResearchedLead();
      researchLead.inputQueueId = lead?.id;
      researchLead.companyName = lead?.name;
      researchLead.enterprise = false;
      if (lead?.phoneNumbers) researchLead.companyPhone = lead?.phoneNumbers[0];
      if (lead?.phoneNumbers) researchLead.companyAdditionalPhone = lead?.phoneNumbers.slice(1).join('\r\n');
      if (lead?.urls) researchLead.companyWebsite = lead?.urls[0];
      researchLead.personName = lead?.name + " - Receptionist";

      if(lead?.addresses !== undefined && lead?.addresses?.length > 0)
      {
        researchLead.companyAddress = lead.addresses[0];

        // if(lead.addresses.length > 1)
        // {
          
        // }
      }
      

      setCurrentLead(researchLead);
      setCurrentInputQueue(lead);
      setStopwatchSecondOffset(lead?.startingSeconds!!);
      setStopWatchKey(stopWatchKey + 1);
    })

    const initialSelected = {};

    Object.values(services).map((val, index) => {
      initialSelected[val.id] = false;
    });

    setSelectedServices(initialSelected);

    const initialEnabledServices = {};

    Object.values(services).map((val, index) => {
      initialEnabledServices[val.id] = true;
    });


    console.log("enabled", initialEnabledServices)

    setEnabledServices(initialEnabledServices);



  }, [leadTrigger]);

  // update lead service value when enabled services change
  useEffect(() => {

    if (selectedServices === undefined) return;

    setCurrentLead((prev) => {
      if (prev === undefined) return;
      let services: number[] = Object.entries(selectedServices)
        .reduce((prev, cur) => {
          if (cur[0] == undefined) return prev;
          if (cur[1]) prev.push(parseInt(cur[0]));
          return prev;
        }, [] as number[]);
      return { ...prev, services: services }
    });

  }, [selectedServices])

  function getTopics() {
    let topics: string[] = [];

    if (currentInputQueue?.isCallAnswering) topics.push("Call Answering");
    if (currentInputQueue?.isCoworking) topics.push("Coworking");
    if (currentInputQueue?.isFlexibleOffice) topics.push("Flexible Office");
    if (currentInputQueue?.isMailbox) topics.push("Mailbox Provider");
    if (currentInputQueue?.isMeetingRooms) topics.push("Meeting Rooms");
    if (currentInputQueue?.isVirtualOffice) topics.push("Virtual Office");

    return topics.join(", ");
  }


  function handleServiceChange(event: any, service: number) {
    // console.log(event, service);


    let toChange = {
      [service]: event.value
    }

    let toDisable = {};

    if (service === 27) {
      toChange[26] = event.value;
      toDisable[28] = !event.value;
    }

    if (service === 28) {
      toChange[26] = event.value;
      toDisable[27] = !event.value;
    }

    if (service === 43) {
      toChange[42] = event.value;
      toDisable[44] = !event.value;
      toDisable[45] = !event.value;
    }

    if (service === 44) {
      toChange[42] = event.value;
      toDisable[43] = !event.value;
      toDisable[45] = !event.value;
    }

    if (service === 45) {
      toChange[42] = event.value;
      toDisable[43] = !event.value;
      toDisable[44] = !event.value;
    }

    // console.log("disable", toDisable);
    // console.log("change", toChange);

    let newServices = { ...selectedServices, ...toChange };
    setSelectedServices((prev) => { return { ...prev, ...toChange } });
    // setSelectedServices(newServices);
    setEnabledServices((prev) => { return { ...prev, ...toDisable } });

    // console.log(selectedServices);
    // setTimeout(() => console.log("selected", selectedServices, newServices), 5000);
  }

  function formValueChange(e: any) {
    console.log(e);
    console.log(e.component.option("formData"));
    setCurrentLead({ ...e.component.option("formData") });
    // setTimeout(() => console.log(currentLead), 1);
  }

  async function saveLead() {

    if (currentLead == undefined) return;
    if (!leadFormRef?.current?.instance.validate().isValid) {
      alert("Pleae check form for errors", "Validation Error");
      return;
    }

    if (currentLead.numberOfLocations == null) {
      let emptyLocation = await confirm("Are you sure you don't want to enter a location count?", "No Location Count");

      if (!emptyLocation) return;
    }


    try {

      let result = await confirm("Are you sure you want to complete this input?", "Complete Input");
      if (!result) return;

      console.log("save", currentLead);
      console.log(leadFormRef?.current?.instance.validate());
      var resp = await saveResearchLead(currentLead);

      setLeadTrigger(leadTrigger + 1);

    } catch (error: any) {
      alert(error, "Error saving")
    }
  }

  async function setNotSuitable() {
    try {
      await setNotSuitableRequest(currentInputQueue?.id!!);
      // window.location.reload();
      setLeadTrigger(leadTrigger + 1);
    }
    catch (error: any) {
      alert(error, "Error marking unsuitable")
    }
  }

  async function skipLead(e: any) {
    console.log("submitted", e);
    e.preventDefault();
    try {
      await skipLeadRequest(currentInputQueue?.id!!, skipReason);
      // window.location.reload();
      setSkipReason("");
      setSkipPopupVisible(false);
      setLeadTrigger(leadTrigger + 1);

    }
    catch (error: any) {
      alert(error, "Error skipping lead")
    }
  }

  async function stopTheClock(reason: StopTheClockReason) {
    console.log(reason);
    try {
      stopLeadClockRequest(currentInputQueue?.id!!, reason);
      setClockRunning(false);
      return true;
    }
    catch (error: any) {
      return false;
    }

  }

  async function startTheClock() {
    console.log("starting");
    setClockRunning(true);
    // window.location.reload();
    setCurrentLead(undefined);
    setLeadTrigger(leadTrigger + 1);
  }

  return (<React.Fragment>

    <h2 className={'content-block'}>Input Leads</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <Box direction="row" width="100%" >
          <Item ratio={1}>
            <SectionBox title="URLs">
              <div className="section-urls">
                {currentInputQueue?.urls?.map((lead, i) => <a key={i} href={lead} target="_blank">{lead}</a>)}
              </div>
            </SectionBox>

          </Item>
          <Item ratio={0} baseSize={10}>
          </Item>
          <Item ratio={1}>

            <SectionBox title="Detected Topics">
              <div className="detected-topics">
                {getTopics()}
              </div>
            </SectionBox>

          </Item>

        </Box>
      </div>
    </div>

    <div className={'content-block'}>
      <Box width="100%">
        <Item ratio={1} baseSize={300}>
          <div className={'control-buttons'}>
            <div className="control-button">
              <Button
                height="40px" width="120px" text="Save" type="success"
                onClick={saveLead}
                disabled={!clockRunning}
              />
            </div>
            <div className="control-button">
              <Button height="40px" width="180px" text="Not Suitable" type="default"
                disabled={!clockRunning}
                onClick={async () => {
                  let result = await confirm("Are you sure you want to mark this lead as unsuitable?", "Mark Unsuitable");
                  if (result) {
                    console.log("unsuitable");
                    setNotSuitable();
                  }
                }} />
            </div>
            <div className="control-button">
              <Popup
                visible={skipPopupVisible}
                closeOnOutsideClick={true}
                title="Skip Lead"
                width={400}
                height="auto"
                onHiding={() => setSkipPopupVisible(!skipPopupVisible)}
              >
                <div>Please enter the reason you wish to skip this lead</div>

                <form onSubmit={skipLead}>
                  <Form id="skip-form" >
                    <FormItem editorType="dxTextArea" editorOptions={{
                      name: "reason",
                      value: skipReason,
                      onValueChanged: (e: any) => setSkipReason(e.value)
                    }}>

                      <RequiredRule message="Reason is required" />

                    </FormItem>

                    <GroupItem cssClass="" colCount={2} >
                      <ButtonItem horizontalAlignment="right" buttonOptions={{
                        text: 'Skip',
                        type: 'default',
                        useSubmitBehavior: true,
                      }} />
                      <ButtonItem horizontalAlignment="left" buttonOptions={{
                        text: "Cancel", type: "danger",
                        onClick: () => {
                          setSkipPopupVisible(false);
                          setSkipReason("");
                        }
                      }} />
                    </GroupItem>
                  </Form>
                </form>

              </Popup>
              <Button height="40px" width="120px" text="Skip" type="danger"
                disabled={!clockRunning}
                onClick={() => setSkipPopupVisible(true)}
              />
            </div>

          </div>
        </Item>
        <Item
          // baseSize={40} 
          ratio={2}
        />
        <Item ratio={1} baseSize={400}>
          {stopwatchSecondOffset !== undefined && <Stopwatch key={stopWatchKey} onStart={startTheClock} onStop={stopTheClock} offset={stopwatchSecondOffset} />}


        </Item>
      </Box>


    </div>

    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        <ValidationGroup ref={validationGroupRef} elementAttr={{ id: "inputValidationGroup" }}>

          <Form
            validationGroup={"inputValidationGroup"}
            formData={currentLead}
            onFieldDataChanged={formValueChange}
            ref={leadFormRef}
            // onContentReady={this.validateForm}
            colCount={2}
            id="form"
          // formData={this.employee}
          >



            <GroupItem colSpan={2} colCount={2} caption="Company Details">
              <FormItem dataField="companyName" >
                <RequiredRule message="Name is required" />
              </FormItem>
              <FormItem dataField="companyWebsite" >
                <RequiredRule message="Website is required" />
              </FormItem>
              <GroupItem colCount={2}>

                <GroupItem>
                  <FormItem dataField="companyPhone">
                    <RequiredRule message="Phone is required" />
                  </FormItem>
                  <SimpleItem cssClass="time-hint" render={() => <TimezoneSuggestion number={currentLead?.companyPhone} />} />
                </GroupItem>
                <FormItem dataField="timezone" editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: new DataSource({
                      store: [
                        { text: "ACST (GMT +8)", value: ResearchLeadTimezone.ACST, category: "Australia" },
                        { text: "AEST (GMT +10)", value: ResearchLeadTimezone.AEST, category: "Australia" },
                        { text: "AST (GMT -4)", value: ResearchLeadTimezone.AST, category: "USA" },
                        { text: "AWST (GMT +8)", value: ResearchLeadTimezone.AWST, category: "Australia" },
                        { text: "CST (GMT -6)", value: ResearchLeadTimezone.CST, category: "USA" },
                        { text: "GMT (GMT +0)", value: ResearchLeadTimezone.GMT, category: "UK" },
                        { text: "HST (GMT -10)", value: ResearchLeadTimezone.HST, category: "USA" },
                        { text: "MST (GMT -7)", value: ResearchLeadTimezone.MST, category: "USA" },
                        { text: "NST (GMT -3.30)", value: ResearchLeadTimezone.NST, category: "Canada" },
                        { text: "PST (GMT -8)", value: ResearchLeadTimezone.PST, category: "USA" },
                        { text: "EST (GMT -5)", value: ResearchLeadTimezone.EST, category: "USA" }
                      ],
                      key: 'text',
                      group: 'category',
                    }),
                    displayExpr: "text",
                    valueExpr: "value",
                    grouped: true
                  }}
                >
                  <RequiredRule message="timezone is required" />
                </FormItem>
              </GroupItem>


              <FormItem dataField="companyAdditionalPhone" editorType="dxTextArea" />
              <FormItem dataField="companyAddress" editorType="dxTextArea" 
                editorOptions={{
                  // value: "yo"
                }}
              />

              <GroupItem caption="Alternative Addresses">

                {currentInputQueue?.addresses?.map((address, index) => {

                  if(currentInputQueue.addresses !== undefined && currentInputQueue.addresses.length < 2) return;
                  if (address == null) return;

                  return <FormItem label={{visible:false}} key={index} dataField="address2" editorOptions={{
                    value: address,

                    buttons: [{
                      name: "select-" + index,
                      location: "after",
                      options: {
                        text: "Select",
                      onClick: () => 
                        leadFormRef.current?.instance.updateData("companyAddress", address)
                      }
                    }]
                  }} />
                })}

              </GroupItem>

              <GroupItem colCount={2}>
                <FormItem dataField="numberOfLocations" editorType="dxNumberBox" />
                <FormItem dataField="enterprise" editorType="dxCheckBox"
                  editorOptions={{
                    text: "Enterprise"
                  }} 
                  label={{visible: false}}
                  />
              </GroupItem>


            </GroupItem>
            <GroupItem colSpan={2} colCount={4} caption="Services">
              {
                services.map((entry, index) =>
                  <SimpleItem
                    key={entry.id}
                    render={(p) => {
                      return <div className="service-item">
                        <CheckBox
                          text={entry.name}
                          value={selectedServices[entry.id]}
                          onValueChanged={(e: any) => handleServiceChange(e, entry.id)}
                          disabled={!enabledServices[entry.id]}
                        />
                        <i
                          onMouseEnter={() => setVisibleToolTip(entry.id)}
                          onMouseLeave={() => setVisibleToolTip(0)}
                          id={"service-" + entry.id} className="dx-icon-help"></i>
                        <Popover
                          target={"#service-" + entry.id}
                          width={400}
                          visible={visibleToolTip == entry.id}
                        >
                          {entry.description}
                        </Popover>
                      </div>

                    }}
                  />)
              }
            </GroupItem>
            <GroupItem colSpan={2} colCount={2} caption="Contact Details">
              <FormItem dataField="personName">
                <RequiredRule message="Person name is required" />
              </FormItem>
              {/*todo: custom validator to check no more than 2 words */}
              <FormItem dataField="personPhone" />
              <FormItem dataField="personEmail" />
            </GroupItem>

            <GroupItem colSpan={2} colCount={2} caption="Extras">
              <FormItem dataField="notes" colSpan={2} editorType="dxTextArea" />
            </GroupItem>

          </Form>
        </ValidationGroup>


      </div>
    </div>

  </React.Fragment>);
}

export function TimezoneSuggestion(props: any) {

  const [suggestedData, setSuggestedData] = useState<any>();
  useEffect(() => {
    // if (props.companyNumber == undefined) return;
    // console.log("number render", props);

    let info = phoneChecker.getLocalInfo(props.number);
    // console.log(info);
    setSuggestedData(info);
  }, [props])


  return (
    <div>{suggestedData?.location} : {suggestedData?.time.zone}</div>
  );
}



