import { JsonServiceClient } from '@servicestack/client';
import * as React from 'react';
import { GetUserInputActionHistoryRequest, GetUserInputActionHistoryResponse } from 'src/common/dtos';
import { useAuth } from 'src/contexts/auth';

export default function useUserInputHistoryApi() {
    const { apiClient } = useAuth() as { apiClient: JsonServiceClient };

    async function getInputActionHistory(loadoptions: any, userId: undefined | number = undefined) : Promise<GetUserInputActionHistoryResponse>
    {
        let req = new GetUserInputActionHistoryRequest();
        req.loadOptions = loadoptions;
        req.userId = userId;

        return apiClient.post(req);

    }

    return { getInputActionHistory } as const;
}