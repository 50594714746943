import { Button, Form, Popup } from 'devextreme-react';
import { ButtonItem, GroupItem, Item, RequiredRule } from 'devextreme-react/form';
import React, {useEffect} from 'react';
import { useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { StopTheClockReason } from 'src/common/dtos';

export default function Stopwatch(props: any) {

  const stopwatchOffset = new Date(); 
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + (props.offset ?? 0));

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true, offsetTimestamp:  stopwatchOffset});
  const [stopPopupVisible, setStopPopupVisible] = React.useState(false);
  const stopFormRef = React.useRef<Form>(null);
  const [stopTheClockReason, setStopTheClockReason] = useState<StopTheClockReason | undefined>();

  // useEffect(() => {


  // }, props);

  function startTimer() {
    props.onStart();
    start();
  }

  function stopTimer()
  {
    if(props.onStop(stopTheClockReason)){
      pause();
    }
  }

  return (
    <div className={'control-buttons to-end'}>
      <div className="control-button">
        <div style={{ fontSize: '32px', lineHeight: "40px", marginRight: "8px" }}>
          <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
        </div>

      </div>
      <div className="control-button">
        <Popup
          visible={stopPopupVisible}
          closeOnOutsideClick={true}
          title="Stop Timer"
          width={400}
          height="auto"
          onHiding={() => setStopPopupVisible(!stopPopupVisible)}
        >

          <Form ref={stopFormRef}>
            <Item editorType="dxRadioGroup" editorOptions={{
              items: [
                { name: "Taking a break", value: StopTheClockReason.TakingBreak }, 
                { name: "Stopping for the day", value: StopTheClockReason.StoppingForDay }, 
                { name: "Working on something else", value: StopTheClockReason.WorkingOnSomethingElse }
              ],
              displayExpr: "name",
              valueExpr: "value",
              onValueChanged: (e) => setStopTheClockReason(e.value)
            }} >
              <RequiredRule message="Reason is required" />
            </Item>
            <GroupItem cssClass="" colCount={2} >
              <ButtonItem horizontalAlignment="right" buttonOptions={{
                text: 'Stop',
                type: 'default',
                // useSubmitBehavior: true,
                onClick: () => {
                  if(!stopFormRef.current?.instance.validate().isValid) return;
                  stopTimer();
                  setStopPopupVisible(false);
                  stopFormRef.current?.instance.resetValues();
                }
              }} />
              <ButtonItem horizontalAlignment="left" buttonOptions={{
                text: "Cancel", type: "danger",
                onClick: () => {
                  setStopPopupVisible(false);
                  stopFormRef.current?.instance.resetValues();
                  if (stopFormRef !== null) stopFormRef.current?.instance?.resetValues();
                }
              }} />
            </GroupItem>
          </Form>

        </Popup>
        <Button visible={isRunning} height="40px" width="120px" text="Stop" type="danger"
          onClick={() => setStopPopupVisible(true)}
        />
        <Button visible={!isRunning} height="40px" width="120px" text="Start" type="success"
          onClick={() => startTimer()}
        />
      </div>
    </div>
  );
}