import React, { useState, createContext, useContext, useEffect } from 'react';
import { useAuth } from './auth';

interface INavigationContext{
  navigationData?: INavigationData
  setNavigationData?(date : INavigationContext)
}

interface INavigationData{
  currentPath: string | null,
}

const NavigationContext = createContext<INavigationContext>({});
const useNavigation = () => useContext(NavigationContext);


function NavigationProvider(props) {
  const [navigationData, setNavigationData] = useState({});
  // const { user } = useAuth();

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher(Component) {
  return function (props) {
    // console.log("component", Component);
    const { path } = props.match;
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      if(setNavigationData !== undefined) setNavigationData({ navigationData:{ currentPath: path }});
    }, [path, setNavigationData]);

    return React.createElement(Component, props);
  }
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}
