import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, ProfilePage, InputLeadsPage, UserActionHistoryPage } from './pages';

const routes = [
  {
    path: '/tasks',
    component: TasksPage,
    permissions: []
  },
  {
    path: '/profile',
    component: ProfilePage,
    permissions: []
  },
  {
    path: '/home',
    component: HomePage,
    permissions: []
  }, 
  {
    path: '/input-leads',
    component: InputLeadsPage,
    permissions: ["Input"]
  },
  {
    path: '/user-action-history',
    component: UserActionHistoryPage,
    permissions: ["Input"]
  }
];

const WatchedRoutes =  routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});

export default WatchedRoutes;