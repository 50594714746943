import defaultUser from '../utils/default-user';
import { client } from '../common/client';
import { Authenticate } from 'src/common/dtos';
export async function signIn(email : string, password : string, rememberMe : boolean) {
  try {
    // Send request
    console.log(email, password);

    let req = new Authenticate();
    req.userName = email;
    req.password = password;
    req.rememberMe =rememberMe;

    let resp = await client.post(req);

    return {
      isOk: true,
      data: resp
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request
    let resp = await client.get(new Authenticate());
    return {
      isOk: true,
      data: resp
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email : string, password : string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email : string, recoveryCode : string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email : string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

export async function signOut()
{
  try {
    // Send request

    let req = new Authenticate();
    req.provider = "logout";

    let resp = await client.post(req);

    return {
      isOk: true,
      data: resp
    };
  }
  catch {
    return {
      isOk: false,
      message: "signout failed"
    };
  }
}
