import { environment } from './environment';
import { JsonServiceClient } from '@servicestack/client';
import { customHistory} from '../App'

let _client = new JsonServiceClient(environment.apiUrl);
_client.onAuthenticationRequired = () => {
  console.log("Redirecting to login");
  //todo:clear user object from state

  return new Promise((resolve, reject) => resolve(true))
};
// _client.useTokenCookie = true;

export let client = _client;

const isNode = typeof process === 'object' &&
  typeof process.versions === 'object' &&
  typeof process.versions.node !== 'undefined';
  
if (isNode) {
  const packageConfig = require("../../package.json");
  let baseUrl = packageConfig["proxy"];
  client = new JsonServiceClient(baseUrl);
  if (baseUrl.startsWith("https://localhost") || baseUrl.startsWith("https://127.0.0.1")) {
    // allow self-signed certs
    client.requestFilter = (req) => {
      const https = require('https');
      (req as any).agent = new https.Agent({ rejectUnauthorized: false });
    };
  }
}