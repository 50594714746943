import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { client } from 'src/common/client';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import { JsonServiceClient } from '@servicestack/client';
import { environment } from '../common/environment';
import { Authenticate, AuthenticateResponse } from 'src/common/dtos';

// interface GetUserInterface {
//   isOk: boolean,
//   data?: AuthenticateResponse,
//   message?: string
// }

interface AuthContextInterface {
  user: AuthenticateResponse | null;
  signIn(email: string, password: string, rememberMe : boolean);
  signOut();
  loading: boolean;
  apiClient: JsonServiceClient
}

const AuthProvider = (props) => {
  const [user, setUser] = useState<AuthenticateResponse | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {

      try {
        // Send request
        let resp = await apiClient.get(new Authenticate());
        setUser(resp);
      }
      catch {
        // ?
      }

      setLoading(false);
    })();
  }, []);

  const apiClient = new JsonServiceClient(environment.apiUrl);
  apiClient.onAuthenticationRequired = () => {
    console.log("Redirecting to login");
    setUser(null);
    return new Promise((resolve, reject) => resolve(true))
  };

  const signIn = async (email, password, rememberMe)  => {

    let result: AuthenticateResponse | null = null;

    try {
      // Send request
      console.log(email, password);

      let req = new Authenticate();
      req.userName = email;
      req.password = password;
      req.rememberMe = rememberMe;

      let resp = await apiClient.post(req);
      result = resp;

      setUser(result);

      return result;
    }
    catch {

    }

    setUser(result);

    return result;
  };

  const signOut = async () => {
    setUser(null);
    try {
      // Send request

      let req = new Authenticate();
      req.provider = "logout";

      let resp = await apiClient.post(req);

      return {
        isOk: true,
        data: resp
      };
    }
    catch {
      return {
        isOk: false,
        message: "signout failed"
      };
    }
  };



  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, apiClient}} {...props} />
  );
}



const AuthContext = createContext<AuthContextInterface>({
  user: null,
  signIn: (email, password, rememberMe) => console.log("default signin"),
  signOut: () => console.log("default signout"),
  loading: false,
  apiClient: new JsonServiceClient()
});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
