import { JsonServiceClient } from '@servicestack/client';
import * as React from 'react';
import { NextInputRequest, ResearchedLead, SaveInputLeadRequest, SetNotSuitableRequest, SkipLeadRequest, StopClockRequest, StopTheClockReason } from 'src/common/dtos';
import { useAuth } from 'src/contexts/auth';


export function useLeadInputApi() {

    const { apiClient } = useAuth();

    async function getNextLead() {
        let req = new NextInputRequest();

        let resp = await apiClient.get(req);

        if (!resp.success) throw new Error(resp.publicMessage);

        return resp.data;
    }

    async function saveResearchLead(lead: ResearchedLead) {
        let req = new SaveInputLeadRequest();
        req.data = lead;

        let resp = await apiClient.post(req);

        if (!resp.success) throw new Error(resp.publicMessage);

        return resp.data;
    }

    async function setNotSuitable(inputQueueId: number) {
        let req = new SetNotSuitableRequest();

        req.inputQueueId = inputQueueId;

        let resp = await apiClient.post(req);

        if (!resp.success) throw new Error(resp.publicMessage);
    }

    async function skipLead(inputQueueId: number, reason: string) {
        let req = new SkipLeadRequest();

        req.inputQueueId = inputQueueId;
        req.reason = reason;

        let resp = await apiClient.post(req);

        if (!resp.success) throw new Error(resp.publicMessage);
    }

    async function stopLeadClock(inputQueueId: number, reason: StopTheClockReason)
    {
        let req = new StopClockRequest();
        req.inputQueueId = inputQueueId;
        req.reason = reason;

        let resp = await apiClient.post(req);

        if (!resp.success) throw new Error(resp.publicMessage);
    }

    return { getNextLead, saveResearchLead, setNotSuitable, skipLead, stopLeadClock } as const; // infers [boolean, typeof load] instead of (boolean | typeof load)[]
}
