/* Options:
Date: 2022-01-05 00:41:55
Version: 5.133
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace: 
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export enum UserRequestType
{
    All = 'All',
    Researcher = 'Researcher',
    Admin = 'Admin',
}

export interface IAdminOverridable
{
    userId?: number;
}

export enum ResearchedLeadStatus
{
    ResearchedFromWebsite = 'ResearchedFromWebsite',
    DetailsGatheredOnCall = 'DetailsGatheredOnCall',
    EmailRequested = 'EmailRequested',
}

export enum ResearchLeadTimezone
{
    None = 'None',
    EST = 'EST',
    CST = 'CST',
    MST = 'MST',
    PST = 'PST',
    AST = 'AST',
    HST = 'HST',
    NST = 'NST',
    GMT = 'GMT',
    AWST = 'AWST',
    ACST = 'ACST',
    AEST = 'AEST',
}

export class ResearchedLead
{
    public id?: number;
    public inputQueueId?: number;
    public companyName?: string;
    public companyPhone?: string;
    public companyAdditionalPhone?: string;
    public companyEmail?: string;
    public companyAddress?: string;
    public companyWebsite?: string;
    public numberOfLocations?: number;
    public services?: number[];
    public personName?: string;
    public personPhone?: string;
    public personEmail?: string;
    public notes?: string;
    public pipedriveRef?: string;
    public userAuthCustomId?: number;
    public status?: ResearchedLeadStatus;
    public timezone?: ResearchLeadTimezone;
    public enterprise?: boolean;

    public constructor(init?: Partial<ResearchedLead>) { (Object as any).assign(this, init); }
}

export enum StopTheClockReason
{
    None = 'None',
    TakingBreak = 'TakingBreak',
    StoppingForDay = 'StoppingForDay',
    WorkingOnSomethingElse = 'WorkingOnSomethingElse',
}

export class DevExtremeLoadOptions
{
    public isLoadingAll?: boolean;
    public requireTotalCount?: boolean;
    public requireGroupCount?: boolean;
    public isCountQuery?: boolean;
    public skip?: number;
    public take?: number;
    public sort?: SortingInfo[];
    public group?: GroupingInfo[];
    public filter?: Object[];
    public totalSummary?: SummaryInfo[];
    public groupSummary?: SummaryInfo[];
    public select?: string[];
    public preSelect?: string[];
    public remoteSelect?: boolean;
    public remoteGrouping?: boolean;
    public expandLinqSumType?: boolean;
    public primaryKey?: string[];
    public defaultSort?: string;
    public stringToLower?: boolean;
    public paginateViaPrimaryKey?: boolean;
    public sortByPrimaryKey?: boolean;
    public allowAsyncOverSync?: boolean;

    public constructor(init?: Partial<DevExtremeLoadOptions>) { (Object as any).assign(this, init); }
}

export interface IDevExtremeQuery
{
    loadOptions?: DevExtremeLoadOptions;
}

export class UserAuthCustomDto
{
    public id?: number;
    public userName?: string;
    public email?: string;
    public primaryEmail?: string;
    public phoneNumber?: string;
    public firstName?: string;
    public lastName?: string;
    public displayName?: string;
    public company?: string;
    public birthDate?: string;
    public birthDateRaw?: string;
    public address?: string;
    public address2?: string;
    public city?: string;
    public state?: string;
    public country?: string;
    public culture?: string;
    public fullName?: string;
    public gender?: string;
    public language?: string;
    public mailAddress?: string;
    public nickname?: string;
    public postalCode?: string;
    public timeZone?: string;
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<UserAuthCustomDto>) { (Object as any).assign(this, init); }
}

export class ApiMessageBase
{
    public success?: boolean;
    public publicMessage?: string;
    public debugMessage?: string;

    public constructor(init?: Partial<ApiMessageBase>) { (Object as any).assign(this, init); }
}

export class ApiListMessage<T> extends ApiMessageBase implements IResponse
{
    public totalCount?: number;
    public offset?: number;
    public data?: T[];

    public constructor(init?: Partial<ApiListMessage<T>>) { super(init); (Object as any).assign(this, init); }
}

export interface IResponse
{
    success?: boolean;
    publicMessage?: string;
}

export class ReportActionStatDailySummary
{
    public day?: string;
    public dayString?: string;
    public averageInputTime?: number;
    public averageNotSuitableTime?: number;
    public averageSkippedTime?: number;
    public totalInputTime?: number;
    public totalNotSuitableTime?: number;
    public totalSkippedTime?: number;
    public totalInputCount?: number;
    public totalNotSuitableCount?: number;
    public totalSkippedCount?: number;
    public totalClockStops?: number;

    public constructor(init?: Partial<ReportActionStatDailySummary>) { (Object as any).assign(this, init); }
}

export enum UserActionCode
{
    None = 'None',
    Inputted = 'Inputted',
    SetNotSuitable = 'SetNotSuitable',
    Skipped = 'Skipped',
    StoppedClock = 'StoppedClock',
}

export class ReportActionStat
{
    public userActionCode?: UserActionCode;
    public day?: string;
    public averageTime?: string;
    public totalActions?: number;
    public totalActiveTime?: string;
    public dayString?: string;

    public constructor(init?: Partial<ReportActionStat>) { (Object as any).assign(this, init); }
}

export enum InputQueueStatus
{
    Waiting = 'Waiting',
    UnSuitable = 'UnSuitable',
    Duplicate = 'Duplicate',
    InProgress = 'InProgress',
    Completed = 'Completed',
    ErrorImporting = 'ErrorImporting',
}

export class InputQueue
{
    public id?: number;
    public name?: string;
    public hostKey?: string;
    public urls?: string[];
    public phoneNumbers?: string[];
    public addresses?: string[];
    public isCoworking?: boolean;
    public isFlexibleOffice?: boolean;
    public isMailbox?: boolean;
    public isCallAnswering?: boolean;
    public isMeetingRooms?: boolean;
    public isVirtualOffice?: boolean;
    public startingSeconds?: number;
    public claimed?: string;
    public status?: InputQueueStatus;
    public inputted?: boolean;
    public pipedriveRef?: string;
    public skippedReason?: string;
    public userAuthCustomId?: number;

    public constructor(init?: Partial<InputQueue>) { (Object as any).assign(this, init); }
}

export class ApiMessage_1<T> extends ApiMessageBase implements IResponse
{
    public data?: T;

    public constructor(init?: Partial<ApiMessage_1<T>>) { super(init); (Object as any).assign(this, init); }
}

export class ApiMessage extends ApiMessageBase implements IResponse
{
    public data?: Object;

    public constructor(init?: Partial<ApiMessage>) { super(init); (Object as any).assign(this, init); }
}

export class UserAction
{
    public id?: number;
    public userAuthCustomId?: number;
    public created?: string;
    public actionDuration?: string;
    public description?: string;
    public data?: Object;
    public action?: UserActionCode;

    public constructor(init?: Partial<UserAction>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public fieldName?: string;

    // @DataMember(Order=3)
    public message?: string;

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public message?: string;

    // @DataMember(Order=3)
    public stackTrace?: string;

    // @DataMember(Order=4)
    public errors?: ResponseError[];

    // @DataMember(Order=5)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class SortingInfo
{
    public selector?: string;
    public desc?: boolean;

    public constructor(init?: Partial<SortingInfo>) { (Object as any).assign(this, init); }
}

export class GroupingInfo extends SortingInfo
{
    public groupInterval?: string;
    public isExpanded?: boolean;

    public constructor(init?: Partial<GroupingInfo>) { super(init); (Object as any).assign(this, init); }
}

export class SummaryInfo
{
    public selector?: string;
    public summaryType?: string;

    public constructor(init?: Partial<SummaryInfo>) { (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result?: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class GetUsersResponse extends ApiListMessage<UserAuthCustomDto>
{

    public constructor(init?: Partial<GetUsersResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUserStatsResponse extends ApiListMessage<ReportActionStat>
{
    public todayInputAverageDuration?: string;
    public yesterdayInputAverageDuration?: string;
    public last7DaysInputAverageDuration?: string;
    public last30DaysInputAverageDuration?: string;
    public todayInputCount?: number;
    public yesterdayInputCount?: number;
    public last7DaysInputCountAverage?: number;
    public last30DaysInputCountAverage?: number;
    public todayNotSuitableAverageDuration?: string;
    public yesterdayNotSuitableAverageDuration?: string;
    public last7DaysNotSuitableAverageDuration?: string;
    public last30DaysNotSuitableAverageDuration?: string;
    public todayNotSuitableCount?: number;
    public yesterdayNotSuitableCount?: number;
    public last7DaysNotSuitableCountAverage?: number;
    public last30DaysNotSuitableCountAverage?: number;
    public todayClockStops?: number;
    public yesterdayClockStops?: number;
    public todayActiveTime?: string;
    public yesterdayActiveTime?: string;
    public last7DaysActiveTime?: string;
    public last30DaysActiveTime?: string;
    public dailySummaries?: ReportActionStatDailySummary[];

    public constructor(init?: Partial<GetUserStatsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class NextInputResponse extends ApiMessage_1<InputQueue>
{

    public constructor(init?: Partial<NextInputResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SaveInputLeadResponse extends ApiMessage_1<ResearchedLead>
{

    public constructor(init?: Partial<SaveInputLeadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetNotSuitableResponse extends ApiMessage
{

    public constructor(init?: Partial<SetNotSuitableResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SkipLeadResponse extends ApiMessage
{

    public constructor(init?: Partial<SkipLeadResponse>) { super(init); (Object as any).assign(this, init); }
}

export class StopClockResponse extends ApiMessage
{

    public constructor(init?: Partial<StopClockResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUserInputActionHistoryResponse extends ApiListMessage<UserAction>
{

    public constructor(init?: Partial<GetUserInputActionHistoryResponse>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId?: string;

    // @DataMember(Order=2)
    public sessionId?: string;

    // @DataMember(Order=3)
    public userName?: string;

    // @DataMember(Order=4)
    public displayName?: string;

    // @DataMember(Order=5)
    public referrerUrl?: string;

    // @DataMember(Order=6)
    public bearerToken?: string;

    // @DataMember(Order=7)
    public refreshToken?: string;

    // @DataMember(Order=8)
    public profileUrl?: string;

    // @DataMember(Order=9)
    public roles?: string[];

    // @DataMember(Order=10)
    public permissions?: string[];

    // @DataMember(Order=11)
    public responseStatus?: ResponseStatus;

    // @DataMember(Order=12)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles?: string[];

    // @DataMember(Order=2)
    public allPermissions?: string[];

    // @DataMember(Order=3)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles?: string[];

    // @DataMember(Order=2)
    public allPermissions?: string[];

    // @DataMember(Order=3)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name?: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

export class GetUsersRequest implements IReturn<GetUsersResponse>
{
    public userType?: UserRequestType;
    public offset?: number;
    public take?: number;

    public constructor(init?: Partial<GetUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUsersResponse(); }
    public getTypeName() { return 'GetUsersRequest'; }
}

export class GetUserStatsRequest implements IReturn<GetUserStatsResponse>, IAdminOverridable
{
    public userId?: number;

    public constructor(init?: Partial<GetUserStatsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserStatsResponse(); }
    public getTypeName() { return 'GetUserStatsRequest'; }
}

// @Route("/input/next", "GET")
export class NextInputRequest implements IReturn<NextInputResponse>
{

    public constructor(init?: Partial<NextInputRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new NextInputResponse(); }
    public getTypeName() { return 'NextInputRequest'; }
}

export class SaveInputLeadRequest implements IReturn<SaveInputLeadResponse>
{
    public data?: ResearchedLead;

    public constructor(init?: Partial<SaveInputLeadRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SaveInputLeadResponse(); }
    public getTypeName() { return 'SaveInputLeadRequest'; }
}

export class SetNotSuitableRequest implements IReturn<SetNotSuitableResponse>
{
    public inputQueueId?: number;

    public constructor(init?: Partial<SetNotSuitableRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetNotSuitableResponse(); }
    public getTypeName() { return 'SetNotSuitableRequest'; }
}

export class SkipLeadRequest implements IReturn<SkipLeadResponse>
{
    public inputQueueId?: number;
    public reason?: string;

    public constructor(init?: Partial<SkipLeadRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SkipLeadResponse(); }
    public getTypeName() { return 'SkipLeadRequest'; }
}

export class StopClockRequest implements IReturn<StopClockResponse>, IAdminOverridable
{
    public inputQueueId?: number;
    public reason?: StopTheClockReason;
    public userId?: number;

    public constructor(init?: Partial<StopClockRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new StopClockResponse(); }
    public getTypeName() { return 'StopClockRequest'; }
}

export class GetUserInputActionHistoryRequest implements IReturn<GetUserInputActionHistoryResponse>, IAdminOverridable, IDevExtremeQuery
{
    public userId?: number;
    public loadOptions?: DevExtremeLoadOptions;

    public constructor(init?: Partial<GetUserInputActionHistoryRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserInputActionHistoryResponse(); }
    public getTypeName() { return 'GetUserInputActionHistoryRequest'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider?: string;

    // @DataMember(Order=2)
    public state?: string;

    // @DataMember(Order=3)
    public oauth_token?: string;

    // @DataMember(Order=4)
    public oauth_verifier?: string;

    // @DataMember(Order=5)
    public userName?: string;

    // @DataMember(Order=6)
    public password?: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView?: string;

    // @DataMember(Order=10)
    public nonce?: string;

    // @DataMember(Order=11)
    public uri?: string;

    // @DataMember(Order=12)
    public response?: string;

    // @DataMember(Order=13)
    public qop?: string;

    // @DataMember(Order=14)
    public nc?: string;

    // @DataMember(Order=15)
    public cnonce?: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken?: string;

    // @DataMember(Order=18)
    public accessTokenSecret?: string;

    // @DataMember(Order=19)
    public scope?: string;

    // @DataMember(Order=20)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions?: string[];

    // @DataMember(Order=3)
    public roles?: string[];

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions?: string[];

    // @DataMember(Order=3)
    public roles?: string[];

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

