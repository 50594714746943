import { Chart, RadioGroup, SelectBox } from 'devextreme-react';
import { ArgumentAxis, CommonSeriesSettings, Grid, Legend, Series, SeriesTemplate, Tooltip } from 'devextreme-react/chart';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useUserReportingApi } from 'src/api/useUserReportingApi';
import { GetUsersRequest, GetUserStatsResponse, ReportActionStatDailySummary, UserActionCode } from 'src/common/dtos';
import { useAuth } from 'src/contexts/auth';
import './home.scss';

export default function Home() {

  const { getUserStats } = useUserReportingApi();
  const [userStats, setUserStats] = useState<GetUserStatsResponse>();
  const { user, apiClient } = useAuth();
  const [currentUser, setCurrentUser] = useState(parseInt(user?.userId ?? "0"));
  const [researchers, setResearchers] = useState<any[]>([]);
  const isAdmin: boolean = user?.roles?.includes("Admin") ?? false;
  const [actionTotalsPeriod, setActionTotalsPeriod] = useState(30);
  const [actionAveragesPeriod, setActionAveragesPeriod] = useState(30);
  const [activeDurationPeriod, setActiveDurationPeriod] = useState(30);
  const [last7DaySummary, setLast7DaySummary] = useState<ReportActionStatDailySummary[]>();

  useEffect(() => {
    if (currentUser == 0) return;
    getUserStats(currentUser).then(resp => {

      setUserStats(resp);
      setLast7DaySummary(resp.dailySummaries?.slice(23))
      console.log(resp);
    });
  }, [currentUser]);

  useEffect(() => {
    (async function () {
      let req = new GetUsersRequest();
      // req.userType = UserRequestType.Researcher;
      let resp = await apiClient.get(req);

      if (resp.success && resp.data !== undefined) {
        setResearchers(resp.data.map(user => {
          return {
            name: user.displayName,
            id: user.id
          }
        }));
      }
      else {
        console.log(resp.publicMessage);
      }
    })()
  }, []);

  function userChange(event) {
    setCurrentUser(event.value);
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Dashboard</h2>

      <div className={'content-block'}>



        {isAdmin &&
          <SelectBox className='user-drop' dataSource={researchers} displayExpr="name" valueExpr="id" defaultValue={parseInt(user?.userId ?? "0")} stylingMode='outlined' onValueChanged={userChange} />
        }

        <div className="summary-blocks">
          <div className='dx-card'>
            <h3>Today</h3>
            <div className="stats">
              <div className="stat">Active Time</div>
              <div className="value">{moment.duration(userStats?.todayActiveTime).hours()} hrs {moment.duration(userStats?.todayActiveTime).hours()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Clock Stop Count</div>
              <div className="value">{userStats?.todayClockStops}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Count</div>
              <div className="value">{userStats?.todayInputCount}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Time Avg.</div>
              <div className="value">{moment.duration(userStats?.todayInputAverageDuration).minutes()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Count</div>
              <div className="value">{userStats?.todayNotSuitableCount}</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Time Avg.</div>
              <div className="value">{moment.duration(userStats?.todayNotSuitableAverageDuration).minutes()} mins</div>
            </div>
          </div>

          <div className='dx-card'>
            <h3>Yesterday</h3>
            <div className="stats">
              <div className="stat">Active Time</div>
              <div className="value">{moment.duration(userStats?.yesterdayActiveTime).hours()} hrs {moment.duration(userStats?.yesterdayActiveTime).minutes()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Clock Stop Count</div>
              <div className="value">{userStats?.yesterdayClockStops}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Count</div>
              <div className="value">{userStats?.yesterdayInputCount}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Time Avg.</div>
              <div className="value">{moment.duration(userStats?.yesterdayInputAverageDuration).minutes()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Count</div>
              <div className="value">{userStats?.yesterdayNotSuitableCount}</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Time Avg.</div>
              <div className="value">{moment.duration(userStats?.yesterdayNotSuitableAverageDuration).minutes()} mins</div>
            </div>
          </div>

          <div className='dx-card'>
            <h3>Last 7 Days</h3>
            <div className="stats">
              <div className="stat">Active Time</div>
              <div className="value">{moment.duration(userStats?.last7DaysActiveTime).humanize()}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Avg.</div>
              <div className="value">{userStats?.last7DaysInputCountAverage}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Time Avg.</div>
              <div className="value">{moment.duration(userStats?.last7DaysInputAverageDuration).minutes()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Avg.</div>
              <div className="value">{userStats?.last7DaysNotSuitableCountAverage}</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Time Avg.</div>
              <div className="value">{moment.duration(userStats?.last7DaysNotSuitableAverageDuration).minutes()} mins</div>
            </div>
          </div>

          <div className='dx-card'>
          <h3>Last 30 Days</h3>
            <div className="stats">
              <div className="stat">Active Time</div>
              <div className="value">{moment.duration(userStats?.last30DaysActiveTime).humanize()}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Avg.</div>
              <div className="value">{userStats?.last30DaysInputCountAverage}</div>
            </div>
            <div className="stats">
              <div className="stat">Input Time Avg.</div>
              <div className="value">{moment.duration(userStats?.last30DaysInputAverageDuration).minutes()} mins</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Avg.</div>
              <div className="value">{userStats?.last30DaysNotSuitableCountAverage}</div>
            </div>
            <div className="stats">
              <div className="stat">Not Suitable Time Avg.</div>
              <div className="value">{moment.duration(userStats?.last30DaysNotSuitableAverageDuration).minutes()} mins</div>
            </div>
          </div>
        </div>

        <h3>Action Totals</h3>
        <RadioGroup
          dataSource={[
            { name: "30 Days", value: 30 },
            { name: "7 Days", value: 7 }
          ]}
          displayExpr="name"
          valueExpr="value"
          // defaultValue={30}
          layout="horizontal"
          onValueChanged={(e) => setActionTotalsPeriod(e.value)}
          value={actionTotalsPeriod}
        />

        <div className={'dx-card responsive-paddings'}>

          {userStats &&

            <Chart dataSource={actionTotalsPeriod == 30 ? userStats.dailySummaries : last7DaySummary}>
              {/* <SeriesTemplate
                nameField="userActionCode"
                
              /> */}
              <CommonSeriesSettings
                argumentField="dayString"
                type="line"
              />
              <Series name="Inputted" argumentField="dayString" valueField="totalInputCount" />
              <Series name="Skipped" argumentField="dayString" valueField="totalSkippedCount" />
              <Series name="Not Suitable" argumentField="dayString" valueField="totalNotSuitableCount" />
              <Series name="Clock Stops" argumentField="dayString" valueField="totalClockStops" />

              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <Grid visible={true} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Tooltip enabled={true} />
            </Chart>
          }

        </div>

        <h3>Average Action Times</h3>
        <RadioGroup
          dataSource={[
            { name: "30 Days", value: 30 },
            { name: "7 Days", value: 7 }
          ]}
          displayExpr="name"
          valueExpr="value"
          // defaultValue={30}
          layout="horizontal"
          onValueChanged={(e) => setActionAveragesPeriod(e.value)}
          value={actionAveragesPeriod}
        />
        <div className={'dx-card responsive-paddings'}>

          {userStats &&
            <Chart dataSource={actionAveragesPeriod == 30 ? userStats.dailySummaries : last7DaySummary}>
              {/* <SeriesTemplate
                nameField="userActionCode"
                
              /> */}
              <CommonSeriesSettings
                argumentField="dayString"
                type="line"
              />
              <Series name="Inputted" argumentField="dayString" valueField="averageInputTime" />
              <Series name="Skipped" argumentField="dayString" valueField="averageSkippedTime" />
              <Series name="Not Suitable" argumentField="dayString" valueField="averageNotSuitableTime" />

              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <Grid visible={true} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Tooltip enabled={true} />
            </Chart>
          }

        </div>

        <h3>Active Duration</h3>
        <RadioGroup
          dataSource={[
            { name: "30 Days", value: 30 },
            { name: "7 Days", value: 7 }
          ]}
          displayExpr="name"
          valueExpr="value"
          // defaultValue={30}
          layout="horizontal"
          onValueChanged={(e) => setActiveDurationPeriod(e.value)}
          value={activeDurationPeriod}
        />
        <div className={'dx-card responsive-paddings'}>

          {userStats &&
            <Chart dataSource={activeDurationPeriod == 30 ? userStats.dailySummaries : last7DaySummary}>
              {/* <SeriesTemplate
                nameField="userActionCode"
                
              /> */}
              <CommonSeriesSettings
                argumentField="dayString"
                type="stackedBar"
              />
              <Series name="Inputted" argumentField="dayString" valueField="totalInputTime" />
              <Series name="Skipped" argumentField="dayString" valueField="totalSkippedTime" />
              <Series name="Not Suitable" argumentField="dayString" valueField="totalNotSuitableTime" />

              <ArgumentAxis
                // valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <Grid visible={true} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Tooltip enabled={true} />
            </Chart>
          }

        </div>

      </div>
    </React.Fragment>
  )
}
