import * as React from 'react';
import { GetUserStatsRequest } from 'src/common/dtos';
import { useAuth } from 'src/contexts/auth';


export function useUserReportingApi()
{
    const { apiClient } = useAuth();

    async function getUserStats(userId? : number)
    {
        let req = new GetUserStatsRequest();
        req.userId = userId;

        let resp = await apiClient.get(req);

        console.log(resp);

        if (!resp.success) throw new Error(resp.publicMessage);

        return resp;
    }

    return { getUserStats } as const;
}