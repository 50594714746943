import * as React from 'react';
import './section-box.scss';

interface ISectionBox {
  title: string;
  children: React.ReactNode
}

export default function SectionBox(props: ISectionBox) {
  return (
    <div className="section-box dx-show-invalid-badge dx-textarea dx-textbox dx-texteditor dx-editor-outlined dx-state-readonly dx-widget dx-visibility-change-handler dx-texteditor-with-floating-label" style={{ width: '100%' }}>
      <div className="">
        <div className="">
          {props.children}
          <div data-dx_placeholder="Type..." className="dx-placeholder dx-state-invisible" /></div>
        <div className="dx-texteditor-buttons-container"><div />
        </div></div>
      <div className="dx-texteditor-label">
        <div className="dx-label-before" style={{ width: 0 }} />
        <div className="dx-label" style={{ maxWidth: 718 }}><span data-mark>{props.title}</span></div>
        <div className="dx-label-after" /></div>
    </div>);
}