import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { IAppNavigation, navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';
import { useAuth } from 'src/contexts/auth';

export default function SideNavigationMenu(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { user } = useAuth();

  const { isLarge } = useScreenSize();

  function filteredNav()
  {
    let filtered : IAppNavigation[] = [];

    if(user === null) return filtered;

    navigation.forEach(route => {
      console.log(route);
      if (route.permissions === undefined || route.permissions.length < 1) 
      {
        filtered.push(route);
        return;
      }

      if(route.permissions.every(elem => user?.permissions?.includes(elem)))
      {
        filtered.push(route);
      }

    });
    
    console.log(filtered);

    return filtered;
  }

  function normalizePath () {
    return filteredNav().map((item) => {
      if(item.path && !(/^\//.test(item.path))){
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLarge};
    });
  }

  

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const { navigationData: { currentPath } } = useNavigation();

  const { navigationData } = useNavigation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if ( navigationData?.currentPath !== undefined) {
      treeView.selectItem(navigationData.currentPath);
      treeView.expandItem(navigationData.currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [navigationData?.currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}
