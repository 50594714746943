export interface IAppNavigation{
  text: string,
  path: string,
  icon: string,
  permissions: string[]
}

export const navigation : IAppNavigation[] = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    permissions: []
  },
  {
    text: 'Input Leads',
    path: '/input-leads',
    icon: 'edit',
    permissions: ["Input"]
  },
  {
    text: 'User Action History',
    path: '/user-action-history',
    icon: 'find',
    permissions: ["Input"]
  }
  ];
