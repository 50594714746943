import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React from 'react';
// import { HashRouter as Router } from 'react-router-dom';
import { Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import { createBrowserHistory } from "history";

export const customHistory = createBrowserHistory();

function App() {
  const { user, loading } = useAuth();

  // console.log("app loading");

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
  
}

export default function Root() {
  // console.log("app loading outer ");
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router history={customHistory}>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
      
    </Router>
  );

  // return <div>Helo World</div>

  // return (
  //   <Router history={customHistory}>
  //     <AuthProvider>
  //       <NavigationProvider>
  //         <div className={`app ${screenSizeClass}`}>
  //           <App />
  //         </div>
  //       </NavigationProvider>
  //     </AuthProvider>
  //   </Router>
  // );
}
