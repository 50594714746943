import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

export default function Content() {

  // console.log(routes);
  const { user } = useAuth();

  function shouldRender(required) {
    if (required === undefined || required.length < 1) return true;

    return false;
  }

  function filteredRoutes()
  {
    let filtered = [];

    routes.forEach(route => {
      console.log(route);
      if (route.permissions === undefined || route.permissions.length < 1) return;

      if(route.permissions.every(elem => user.permissions.includes(elem)))
      {
        filtered.push(route);
      }

    });
    
    console.log(filtered);

    return filtered;
  }

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routes.map(({ path, component, permissions }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
            // render={() => shouldRender(permissions) ? component : undefined}
          />
        ))}
        <Redirect to={'/home'} />
      </Switch>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their
        respective owners.
      </Footer>
    </SideNavBarLayout>
  );
}
